import './style.scss';

import { FilterStatus } from '@components/modules/filter-status';
import { PAGE_SIZE } from '@constants/index.constant';
import { MQTT_TOPIC } from '@constants/mqtt-topic';
import { IssueStatus } from '@enums/helpdesk.enum';
import { IHelpdesk } from '@interfaces/helpdesk';
import { useTable, useTranslate } from '@refinedev/core';
import { fromNow } from '@utils/date';
import { getPublicMediaUrl } from '@utils/resource';
import { useSubscription } from '@zodinet-pos/core-ui';
import { Avatar, Col, Image, Input, Row, Spin } from 'antd';
import _, { cloneDeep } from 'lodash';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';

const LIST_HELPDESK_STATUS = [
    {
        label: 'Open',
        value: IssueStatus.OPEN,
    },
    {
        label: 'Closed',
        value: IssueStatus.CLOSED,
    },
];
interface IHelpdeskListProps {
    handleClickTicket: (ticket: IHelpdesk.Ticket) => void;
    topic: string;
}

export const HelpdeskListComponent: React.FC<IHelpdeskListProps> = ({
    handleClickTicket,
    topic,
}: IHelpdeskListProps) => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}') || {};
    const [data, setData] = useState<IHelpdesk.Ticket[]>([]);
    const translate = useTranslate();
    const { message: mqttCloseTicket } = useSubscription.default(MQTT_TOPIC.CLOSE_TICKET);
    const [filter, setFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams();
    const [srolled, setSrolled] = useState<boolean>(true);
    const { message: mqttTicket } = useSubscription.default(topic);
    const searchQuery = window?.location?.search
        ? new URLSearchParams(window.location.search)
        : null;
    const currTicketStatus = searchQuery ? searchQuery.get('status') : null;

    const tickets = useTable<IHelpdesk.Ticket>({
        resource: `v1/tickets/tenant`,
        queryOptions: {
            enabled: false,
        },
        sorters: {
            initial: [
                {
                    field: 'createdAt',
                    order: 'desc',
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
        filters: {
            initial: [
                {
                    field: 'status',
                    value: currTicketStatus || null,
                    operator: 'eq',
                },
                {
                    field: 'filter',
                    value: filter,
                    operator: 'eq',
                },
                {
                    field: 'toDate',
                    value: new Date(),
                    operator: 'eq',
                },
                {
                    field: 'loadMore',
                    value: true,
                    operator: 'eq',
                },
            ],
        },
    });

    useEffect(() => {
        if (id && document && window && srolled) {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                setSrolled(false);
            }
        }
    }, [tickets]);

    useEffect(() => {
        setData([]);
        if (filter) {
            tickets.setFilters([
                {
                    field: 'filter',
                    value: filter,
                    operator: 'eq',
                },
                {
                    field: 'toDate',
                    value: new Date(),
                    operator: 'eq',
                },
            ]);
        }
    }, [filter]);

    useEffect(() => {
        tickets.tableQueryResult.refetch();
    }, [tickets.current, tickets.filters]);

    useEffect(() => {
        if (mqttTicket) {
            if (mqttTicket.message) {
                let newTicketList = [...data];
                const newTicket = JSON.parse(mqttTicket.message.toString());
                if (newTicket) {
                    newTicketList = newTicketList?.filter((item) => item.id !== newTicket.id);
                    newTicketList.unshift(newTicket);
                    setData(newTicketList);
                }
            }
        }
    }, [mqttTicket]);

    useEffect(() => {
        if (mqttCloseTicket) {
            if (mqttCloseTicket.message) {
                const newTicketList = [...data];
                const newTicket = JSON.parse(mqttCloseTicket.message.toString());
                newTicketList.forEach((item) => {
                    if (item.id === newTicket.id) {
                        item.status = IssueStatus.CLOSED;
                    }
                });
                setData(newTicketList);
            }
        }
    }, [mqttCloseTicket]);

    useMemo(() => {
        if (tickets) {
            if (tickets.current > 1) {
                const messageListCloneDeep = cloneDeep(tickets.tableQueryResult?.data?.data);
                const newTicketList = [...(data || []), ...(messageListCloneDeep || [])];
                const uniqueList = _.uniqBy(newTicketList, 'id');
                setData(uniqueList);
            } else {
                setData(tickets.tableQueryResult.data?.data as IHelpdesk.Ticket[]);
            }
        }
    }, [tickets.tableQueryResult?.data?.data]);

    const loadMore = () => {
        tickets.setFilters([
            {
                field: 'toDate',
                value: data[data.length - 1].createdAt,
                operator: 'eq',
            },
        ]);
        tickets.setCurrent(tickets.current + 1);
    };

    const changeHandler = (event: any) => {
        const filter = event.target.value;
        if (filter) {
            setFilter(filter);
        } else {
            setData([]);
            tickets.setFilters([
                {
                    field: 'filter',
                    value: '',
                    operator: 'eq',
                },
                {
                    field: 'toDate',
                    value: new Date(),
                    operator: 'eq',
                },
            ]);
        }
    };

    const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

    const onChangeStatus = (status: string) => {
        setData([]);
        tickets.setFilters([
            {
                field: 'status',
                value: status,
                operator: 'eq',
            },
            {
                field: 'filter',
                value: '',
                operator: 'eq',
            },
            {
                field: 'toDate',
                value: new Date(),
                operator: 'eq',
            },
        ]);
    };

    return (
        <div
            id="helpdeskListContainer"
            className="helpdesk-list-container w-full h-full flex flex-col"
        >
            <Row className="search-helpdesk">
                <Col span={24}>
                    <Row className="h-10">
                        <Col span={12} className="filter-wrapper justify-between items-center">
                            <span className="all-issues">{translate('helpdesk.all_issues')}</span>
                        </Col>
                        <Col span={12} className="flex items-center justify-end">
                            <FilterStatus
                                placeholder="Select status"
                                listStatus={LIST_HELPDESK_STATUS}
                                className="search-helpdesk-status w-4/5"
                                onChangeStatus={(status: string) => onChangeStatus(status)}
                                defaultValue={currTicketStatus || undefined}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4 ">
                        <Input
                            className="search-input"
                            placeholder={translate('helpdesk.search_for_label')}
                            prefix={<Image src={'/images/icons/search-lg.svg'} preview={false} />}
                            onChange={debouncedChangeHandler}
                        />
                    </Row>
                </Col>
            </Row>

            <Row id="ticketContainer" className="ticket-list flex-1">
                <Col span={24}>
                    <InfiniteScroll
                        dataLength={data?.length ?? 0}
                        next={loadMore}
                        hasMore={tickets.tableQueryResult.data?.data?.length === PAGE_SIZE}
                        loader={loading ? <Spin></Spin> : null}
                        scrollableTarget="ticketContainer"
                    >
                        {data
                            ? data.map((item) => (
                                  <div
                                      id={item?.id}
                                      key={item?.id}
                                      className={`conversation-card flex cursor-pointer ${
                                          item.id === id && 'conversation-card-active'
                                      }`}
                                      onClick={() => handleClickTicket(item)}
                                  >
                                      <Avatar
                                          className="conversation-card-avatar ml-3"
                                          size="large"
                                          icon={
                                              item?.user?.avatarUrl ? (
                                                  <Image
                                                      className="customer-cell-avatar"
                                                      src={getPublicMediaUrl(item?.user?.avatarUrl)}
                                                      preview={false}
                                                  />
                                              ) : (
                                                  <Avatar
                                                      style={{
                                                          verticalAlign: 'middle',
                                                      }}
                                                      size="large"
                                                  >
                                                      {item?.user?.firstName?.charAt(0)}
                                                  </Avatar>
                                              )
                                          }
                                      />
                                      <div className="conversation-card-content flex flex-col items-start ml-3">
                                          <div className="flex items-center">
                                              <p className="customer-name-card">{`${item?.user?.firstName} ${item?.user?.lastName}`}</p>
                                              {item?.lastestCreatedAt && (
                                                  <p className="message-time ml-2">
                                                      {fromNow(item?.lastestCreatedAt)}
                                                  </p>
                                              )}
                                          </div>

                                          {/* <div className="partner-name">
                                              {item?.partner?.userType ===
                                              UserTypeEnum.AFFILIATE_PARTNER
                                                  ? getFullNameUser(item?.partner)
                                                  : item?.partner?.name}
                                          </div> */}

                                          <div className="status-container flex">
                                              {item.status && (
                                                  <p className={`status-text ${item.status}`}>
                                                      {item?.status.charAt(0).toUpperCase() +
                                                          item?.status.slice(1)}
                                                  </p>
                                              )}

                                              {item?.priority && (
                                                  <p
                                                      className={`priority-text flex items-center ${item.priority}`}
                                                  >
                                                      <span className="dot"></span>
                                                      <span>
                                                          {item?.priority.charAt(0).toUpperCase() +
                                                              item?.priority.slice(1)}
                                                      </span>
                                                  </p>
                                              )}
                                          </div>

                                          <p className="subject-name-card">{item?.subject}</p>

                                          <div className="flex items-center">
                                              <p className="message-card">{item?.lastestMessage}</p>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </InfiniteScroll>
                </Col>
            </Row>
        </div>
    );
};
