import {
    DATE_FORMAT,
    DATE_FORMAT_MINUTE,
    DATE_FORMAT_PERIOD,
    DATE_FORMAT_TIME,
} from '@constants/index.constant';
import { IMarketplace } from '@interfaces/marketplace';
import { SubscriptionInvoiceStatus } from 'enums/subscription-invoice-status.enum';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// dayjs config
dayjs.extend(utc);

export const RECURRING_SUBSCRIPTION_SUBTRACT_DAY = 1;

export const formatDate = (date: Date, format = DATE_FORMAT_PERIOD): string => {
    if (!date) return '';
    return dayjs.utc(date).local().format(format);
};

export const toLocaleDateTime = (date: Date): string => {
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
};
export const formatDateTime = (date: string | Date): string => {
    return dayjs.utc(date).format(DATE_FORMAT_MINUTE);
};

export const fromNow = (date: string | Date): string => {
    const localTime = dayjs.utc(date).local().format(DATE_FORMAT_TIME);
    return dayjs.utc(localTime).fromNow(true);
};

export const fromNowNotAgoText = (date: string | Date): string => {
    const localTime = dayjs.utc(date).local().format(DATE_FORMAT_TIME);
    return dayjs.utc(localTime).fromNow(true);
};

export const subtractDay = (num: number, currDay: dayjs.Dayjs): Date => {
    const result = currDay.subtract(num, num > 1 ? 'days' : 'day').toDate();
    return result;
};

export const formatAppSubscriptionPeriodEnd = (
    num: number,
    currentDay?: Date,
    invoice?: IMarketplace.ISubscriptionInvoice,
) => {
    const _currDay = currentDay ? dayjs.utc(currentDay) : dayjs.utc();
    if (invoice?.status === SubscriptionInvoiceStatus.Paid && !invoice?.stripeInvoiceId) {
        return formatDate(_currDay.toDate(), DATE_FORMAT);
    }

    return formatDate(subtractDay(num, _currDay), DATE_FORMAT);
};
