import { Create, Edit } from '@refinedev/antd';
import { ButtonProps, Drawer, DrawerProps, FormProps, Grid } from 'antd';

import { BusinessTypeDetailForm } from './detail-form';

type TenantDetailsProps = {
    drawerProps: DrawerProps;
    formProps: FormProps;
    saveButtonProps: ButtonProps;
    isEditMode: boolean;
};

export const BusinessTypeDetail: React.FC<TenantDetailsProps> = ({
    drawerProps,
    formProps,
    saveButtonProps,
    isEditMode,
}) => {
    const breakpoint = Grid.useBreakpoint();
    const initialValues: any =
        !isEditMode && !formProps.initialValues?.id
            ? { isActive: true }
            : {
                  ...formProps.initialValues,
              };

    return (
        <>
            {drawerProps.open && (
                <Drawer
                    {...drawerProps}
                    width={breakpoint.sm ? '700px' : '100%'}
                    maskClosable={false}
                    zIndex={100}
                    className="drawer-form-container contact-drawer tenant-detail"
                    afterOpenChange={() => {
                        formProps.form?.resetFields();
                    }}
                >
                    {!isEditMode ? (
                        <Create
                            breadcrumb={null}
                            headerProps={{ extra: null, backIcon: null }}
                            saveButtonProps={saveButtonProps}
                        >
                            <BusinessTypeDetailForm
                                formProps={formProps}
                                mode={'add'}
                                isCreate={true}
                            />
                        </Create>
                    ) : (
                        <Edit
                            breadcrumb={null}
                            isLoading={!initialValues?.id ? true : false}
                            saveButtonProps={saveButtonProps}
                            headerProps={{ extra: null, backIcon: null }}
                        >
                            <BusinessTypeDetailForm formProps={formProps} mode={'edit'} />
                        </Edit>
                    )}
                </Drawer>
            )}
        </>
    );
};
