import './styles.scss';

import { CaretDownOutlined } from '@ant-design/icons';
import { PERMISSIONS } from '@constants/permission';
import { ILocation } from '@interfaces/location';
import { getTimezone, LocationComponent } from '@zodinet-pos/core-ui';
import { useCustom, useCustomMutation, usePermissions, useTranslate } from '@refinedev/core';
import { Button, Col, Form, Input, notification, Row, Select, Spin } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import Response from 'data-access/responses/response';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import { getEnvConfig } from 'getEnvConfig';
import { Setting } from 'interfaces/setting';
import React, { useEffect, useState } from 'react';
import { validateEmail, validatePhoneNumber } from 'utils/validator';

dayjs.extend(timezone);
dayjs.extend(dayjsRelativeTime);
dayjs.extend(advancedFormat);
interface ISettingProps {
    [name: string]: string;
}

enum CompanySettingFormField {
    //company profile
    COMPANY_NAME = 'name',
    SLUG = 'slug',
    WEBSITE_ADDRESS = 'websiteAddress',
    PHONE = 'phone',
    EMAIL = 'email',
    STREET = 'street',
    CITY = 'city',
    STATE = 'state',
    ZIP_CODE = 'zipCode',
    TIMEZONE = 'timezone',
    PIN_LOGIN = 'pinLogin',
    LOCATION = 'location',
}

export const SettingCompanyProfile: React.FC<ISettingProps> = () => {
    const translate = useTranslate();
    const [location, setLocation] = useState<ILocation.ILocationResponse | null>(null);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [form] = Form.useForm();
    const apiUrl = getEnvConfig.PORTAL_URL;
    const url = `${apiUrl}/v1/settings/company-profile`;
    const [loading, setLoading] = useState<boolean>(false);
    const { data: rights } = usePermissions<string[]>();
    const [timezones, setTimezones] = useState<Setting.ITimeZone[]>([]);
    const { data } = useCustom<Response<Setting.ICompanySetting>>({
        url: url,
        method: 'get',
        config: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            },
        },
    });
    const { mutate } = useCustomMutation<Response<boolean>>();

    useEffect(() => {
        getTimeZones();
    }, []);

    useEffect(() => {
        const response = data?.data;
        if (response?.isSuccess) {
            const data = response.data;

            console.log('data', data);
            form.setFieldsValue({
                [CompanySettingFormField.COMPANY_NAME]: data.name,
                [CompanySettingFormField.SLUG]: data.name,
                [CompanySettingFormField.WEBSITE_ADDRESS]: data.websiteAddress,
                [CompanySettingFormField.PHONE]: data.phone,
                [CompanySettingFormField.EMAIL]: data.email,
                [CompanySettingFormField.STREET]: data.street,
                [CompanySettingFormField.CITY]: data.city,
                [CompanySettingFormField.STATE]: data.state,
                [CompanySettingFormField.ZIP_CODE]: data.zipCode,
                [CompanySettingFormField.TIMEZONE]: data.timezone,
                [CompanySettingFormField.PIN_LOGIN]: data.pinLogin,
            });
            if (data.location) {
                setLocation(data.location);
            }
        }
    }, [data?.data]);

    const onValidation = async (result?: boolean, data?: ILocation.ILocationResponse) => {
        try {
            const values = await form.validateFields();

            setIsSubmit(false);

            if (!result || !data) {
                setLoading(false);
                return;
            }

            const { postalCode, ...rest } = data;

            const response = {
                ...values,
                location: rest,
            };

            mutate(
                {
                    url: url,
                    method: 'post',
                    values: response,
                    config: {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
                        },
                    },
                    errorNotification: {
                        message: translate('update_err'),
                        type: 'error',
                    },
                },
                {
                    onSuccess: (data) => {
                        const response = data.data;
                        if (response.isSuccess) {
                            notification.success({ message: translate('update_success') });
                        } else {
                            notification.error({ message: translate('update_err') });
                        }
                        form.resetFields(['phone']);
                        form.setFieldsValue({
                            ...values,
                        });
                        setLoading(false);
                    },
                    onError: () => {
                        notification.error({ message: translate('update_err') });
                        setLoading(false);
                    },
                },
            );
        } catch (err) {
            setIsSubmit(false);
            setLoading(false);

            console.warn(err);
        }
    };

    const getTimeZones = () => {
        const zoneCodes = getTimezone.default();
        setTimezones(zoneCodes);
    };

    const onFinish = () => {
        setLoading(true);
        setIsSubmit(true);
    };

    return (
        <Row
            className="w-full tenant-setting-containers company-profile-container"
            id="tenantSettingContainers"
        >
            <Col span={24}>
                <Row className="tenant-setting-header-row">
                    <Col className="tenant-setting-header-col w-full h-full items-center" span={24}>
                        <h2 className="tenant-setting-header-title">
                            {translate('settings.company_profile')}
                        </h2>
                    </Col>
                </Row>
                <Spin spinning={loading}>
                    <Form form={form} layout={'vertical'} onFinish={onFinish}>
                        <Row className="tenant-setting-form-row pt-6">
                            <Col span={24}>
                                <Row className="tenant-setting-form-item-row" gutter={33}>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            name={CompanySettingFormField.TIMEZONE}
                                            label={
                                                <span className="tenant-setting-form-item-label">
                                                    {translate(`settings.time_zone`)}
                                                </span>
                                            }
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        return value
                                                            ? Promise.resolve()
                                                            : Promise.reject(
                                                                  translate(
                                                                      'settings.timezone_required',
                                                                  ),
                                                              );
                                                    },
                                                },
                                            ]}
                                        >
                                            <Select
                                                className="tenant-setting-form-item-select"
                                                suffixIcon={<CaretDownOutlined />}
                                                options={timezones}
                                            ></Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            name={CompanySettingFormField.PHONE}
                                            label={
                                                <span className="tenant-setting-form-item-label">
                                                    {translate(`settings.phone_number`)}
                                                </span>
                                            }
                                            rules={[
                                                // {
                                                //     pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                                //     message: translate(
                                                //         'settings.phone_number_required',
                                                //     ),
                                                // },
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            value !== '(___)-___-____' &&
                                                            !validatePhoneNumber(value)
                                                        ) {
                                                            return Promise.reject(
                                                                translate(
                                                                    'settings.phone_number_required',
                                                                ),
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <MaskedInput
                                                className="tenant-setting-form-item-input"
                                                mask={
                                                    //  https://imask.js.org/guide.html#masked-pattern
                                                    '(000)-000-0000'
                                                }
                                            />
                                            {/* <Input
                                                className="tenant-setting-form-item-input"
                                                maxLength={20}
                                            /> */}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="tenant-setting-form-row pb-8">
                            <Col span={24}>
                                <Row className="tenant-setting-form-item-row" gutter={33}>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            name={CompanySettingFormField.COMPANY_NAME}
                                            label={
                                                <span className="tenant-setting-form-item-label">
                                                    {translate(`settings.company_name`)}
                                                </span>
                                            }
                                        >
                                            <Input
                                                className="tenant-setting-form-item-input"
                                                maxLength={200}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            name={CompanySettingFormField.EMAIL}
                                            label={
                                                <span className="tenant-setting-form-item-label">
                                                    {translate(`settings.email`)}
                                                </span>
                                            }
                                            rules={[
                                                {
                                                    validator: (_, value) => {
                                                        if (value && !validateEmail(value)) {
                                                            return Promise.reject(
                                                                translate('settings.email_invalid'),
                                                            );
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="tenant-setting-form-item-input"
                                                maxLength={100}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row className="tenant-setting-form-item-row" gutter={33}>
                                    <Col xs={24} lg={12}>
                                        <Form.Item
                                            name={CompanySettingFormField.WEBSITE_ADDRESS}
                                            label={
                                                <span className="tenant-setting-form-item-label">
                                                    {translate(`settings.website_address`)}
                                                </span>
                                            }
                                        >
                                            <Input
                                                className="tenant-setting-form-item-input"
                                                maxLength={300}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <LocationComponent
                                location={location}
                                isSubmit={isSubmit}
                                setIsSubmit={setIsSubmit}
                                onValidation={onValidation}
                                className="location-component"
                                isPosCode={false}
                            />

                            {/* <Col span={24}>
                                <Row className="tenant-setting-form-item-row" gutter={33}>
                                    <Col
                                        className="tenant-setting-form-item-label w-full h-full items-center"
                                        span={24}
                                    >
                                        <h1 className="tenant-setting-form-item-label">
                                            {translate('settings.login_pin_code')}
                                        </h1>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <p className="pin-hint mb-2">{translate('pin.hint')}</p>
                                        <Form.Item
                                            name={CompanySettingFormField.PIN_LOGIN}
                                            rules={[
                                                {
                                                    validator: (_, value, __) => {
                                                        if (!value) return Promise.resolve();
                                                        if (!/^[0-9]+$/.test(value))
                                                            return Promise.reject(
                                                                translate(
                                                                    'settings.login_pin_code_only_number',
                                                                ),
                                                            );
                                                        if (value?.length < PIN_LENGTH)
                                                            return Promise.reject(
                                                                translate(
                                                                    'settings.login_pin_code_max_length',
                                                                    {
                                                                        pinLength: PIN_LENGTH,
                                                                    },
                                                                ),
                                                            );
                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input.Password
                                                className="tenant-setting-form-item-input"
                                                placeholder={translate(
                                                    'settings.login_pin_code_placeholder',
                                                )}
                                                maxLength={PIN_LENGTH}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col> */}

                            {(rights || []).includes(PERMISSIONS.SETTING_EDIT) ? (
                                <Col span={24} className="">
                                    <Row className="tenant-setting-button-group mt-16">
                                        <Button
                                            className="tenant-setting-button-group-item"
                                            htmlType="submit"
                                            type="primary"
                                        >
                                            {translate('buttons.save')}
                                        </Button>
                                    </Row>
                                </Col>
                            ) : null}
                        </Row>
                    </Form>
                </Spin>
            </Col>
        </Row>
    );
};
