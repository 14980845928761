import { DATE_FORMAT } from '@constants/index.constant';
import { IUser } from '../interfaces/user';
import { getEnvConfig } from '../getEnvConfig';
import { CurrencyType } from 'enums/currency-type.enum';
import dayjs from 'dayjs';
import { LocaleCurrency } from '@enums/currency.enum';

export const getPublicMediaUrl = (url: string): string => {
    if (url && url.indexOf('http') === -1) {
        return `${getEnvConfig.CDN}/${url}`;
    }
    return `${url}`;
};

export const formatPrice = (price = 0, currencySymbol = '$'): string => {
    if (!price) {
        price = 0;
    }
    return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const convertPrice = (price = 0, currencySymbol = '$'): string => {
    if (!price) {
        price = 0;
    }
    return (price / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

export const formatPriceBaseOnCurrency = (price: number, currency: string): string => {
    let localeString;
    switch (currency) {
        case CurrencyType.Usd:
            localeString = LocaleCurrency.USD;
            break;

        case CurrencyType.Vnd:
            localeString = LocaleCurrency.VND;
            break;
    }

    const formatter = new Intl.NumberFormat(localeString ?? 'en-US', {
        style: 'currency',
        currency: currency ?? 'USD',
    }).format(price / 100 || 0);

    const formatVND = formatter.replaceAll('.', ',');
    return currency === CurrencyType.Vnd ? formatVND : formatter;
};

export const formatDate = (date: string | undefined, format = DATE_FORMAT): string => {
    if (!date) return '';
    return dayjs(date).format(format);
};

export const getFullNameUser = (user: IUser.IUserDto): string =>
    `${user?.firstName ? user?.firstName + ' ' : ''}${user?.lastName ?? ''}`;
