import { CustomModal } from '@components/modules/modal-custom';
import { CurrencyType } from '@enums/currency-type.enum';
import { formatPriceBaseOnCurrency } from '@utils/resource';
import { Image, notification } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import React from 'react';
import { useTranslation } from 'react-i18next';

type QRModalProps = {
    url: string;
    isOpen: boolean;
    planPrice: number;
    expiredTime: number;
    onClose: () => void;
    discount: number;
    qtyTable: number;
};

const QR_TIMEOUT = 30 * 60 * 1000;

export const QRModal: React.FC<QRModalProps> = ({
    url,
    isOpen,
    planPrice,
    expiredTime,
    onClose,
    discount,
    qtyTable,
}) => {
    const { t } = useTranslation('common');
    const months = expiredTime * 12;
    return (
        <CustomModal
            open={isOpen}
            footer={false}
            closable={true}
            onCancel={onClose}
            maskClosable={false}
        >
            <div className="w-full h-full flex justify-center items-center">
                <Image src={url} preview={false} />
            </div>

            <div className="w-full text-center mt-2">
                <Countdown
                    title={
                        <div className="flex flex-col w-full justify-center">
                            <div className="flex flex-col mb-4 text-xl">
                                <p className="font-bold text-blue-500">
                                    {t('plan_form.plan_price')}
                                </p>
                                <p className="font-bold text-blue-500">
                                    {formatPriceBaseOnCurrency(
                                        (planPrice * months * qtyTable * (100 - discount)) / 100,
                                        CurrencyType.Vnd,
                                    )}
                                </p>
                            </div>
                            <span className="text-sm">{t('plan_form.qrcode_countdown')}</span>
                        </div>
                    }
                    value={Date.now() + QR_TIMEOUT}
                    valueStyle={{
                        color: 'rgba(0, 0, 0, 0.45)',
                    }}
                    onFinish={() => {
                        notification.error({
                            message: t('plan_form.qrcode_expired'),
                            type: 'error',
                        });

                        onClose();
                    }}
                />
            </div>
        </CustomModal>
    );
};
