import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { APPLICATIONS, MESSAGE_SALES_PLAN_FREE_SUCCESS } from '@constants/index.constant';
import { CurrencyType } from '@enums/currency-type.enum';
import { NApplication } from '@interfaces/application';
import { useApiUrl, useCustomMutation, useOne, useSetLocale } from '@refinedev/core';
import { formatPriceBaseOnCurrency } from '@utils/resource';
import { Connector } from '@zodinet-pos/core-ui';
import { Button, Col, Form, Input, InputNumber, Layout, Row, Spin } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { NotificationPaymentModal } from 'pages/extra-application-plan/notification-payment-modal';
import { QRModal } from 'pages/extra-application-plan/qr-modal';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getEnvConfig } from '../../getEnvConfig';
import { validateIntNumber } from '../../utils/validator';
import s from './styles.module.scss';

const enum FieldTypeEnum {
    EMAIL = 'email',
    PASSWORD = 'password',
    FULL_NAME = 'fullName',
    PHONE_NUMBER = 'phoneNumber',
    QTY_TABLE = 'qtyTable',
    EXPIRED_TIME = 'expiredTime',
    REFERENCE_CODE = 'referenceCode',
    SERVICE_PACKAGE = 'servicePackage',
}

const MAX_QTY_TABLE_FREE = 30;

export const ExtraApplicationPlanIndex = () => {
    const apiUrl = useApiUrl();
    const recaptchaRef = useRef<any>();

    const [form] = Form.useForm();
    const { t } = useTranslation(['common']);
    const { CAPTCHA_SITE_KEY } = getEnvConfig;

    const [qrUrl, setQrUrl] = useState<string>();
    const [qtyTable, setQtyTable] = useState<number>(1);
    const [tenantId, setTenantId] = useState<string>('');
    const [planPrice, setPlanPrice] = useState<number>(0);
    const [expiredTime, setExpiredTime] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [extraAppPlanId, setExtraAppPlanId] = useState<string>();
    const [extraAppPlanName, setExtraAppPlanName] = useState<string>();
    const [isOpenQrModal, setIsOpenQrModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string>();
    const [refCode, setRefCode] = useState<string>();
    const [discount, setDiscount] = useState<number>(0);
    const { mutate: createTenant } = useCustomMutation();
    const { id } = useParams();
    const changeLang = useSetLocale();

    const { data: extraAppPlans } = useOne<NApplication.IExtraAppPlan[]>({
        resource: 'v1/application/extra-app-plans',
        id: APPLICATIONS.POS.id,
        queryOptions: {
            enabled: true,
        },
    });

    const { data: registration } = useOne<NApplication.IPlanResponseDto>({
        resource: 'v1/tenant/sales-package-plan',
        id: id,
        queryOptions: {
            enabled: !!id,
        },
    });

    useEffect(() => {
        changeLang('vi');
        if (id && registration?.data) {
            form.setFieldsValue(registration.data);
            setExtraAppPlanId(registration.data.extraAppPlanId);
            setPlanPrice(registration.data.planPrice);
            setQtyTable(registration.data.qtyTable);
            setExtraAppPlanName(registration.data.extraAppPlanName);
            setExpiredTime(registration.data.expiredTime);
            setRefCode(registration.data.referenceCode);
            setDiscount(registration.data.discount || 0);
        }
    }, [registration, qrUrl, tenantId]);

    const handleSubmit = async (values: NApplication.IPlanRequest) => {
        setIsLoading(true);

        const requestForm: NApplication.IPlanRequest = {
            ...values,
            planPrice,
            extraAppPlanId: extraAppPlanId ?? '',
            extraAppPlanName: extraAppPlanName ?? '',
            id: id || undefined,
        };

        const recaptchaToken = await recaptchaRef.current.executeAsync();

        createTenant(
            {
                url: `${apiUrl}/v1/tenant/sales-package-plan`,
                method: 'post',
                values: requestForm,
                config: {
                    headers: {
                        recaptchavalue: recaptchaToken ?? null,
                    },
                },
                errorNotification: (error) => {
                    setIsLoading(false);

                    return {
                        message: t(
                            `plan_form.errors.${
                                error?.statusCode === 500 || !error?.response?.data?.error
                                    ? 'register_failed'
                                    : error?.response?.data?.error
                            }`,
                        ),
                        type: 'error',
                    };
                },
            },
            {
                onSuccess: (data) => {
                    if (typeof data.data?.data == 'boolean') {
                        setTitle('notifications.waiting_collaborator');
                    }
                    const response: NApplication.IPlanResponse = data.data?.data;

                    if (data?.data?.isSuccess) {
                        setTenantId(response.tenantId);
                        setQrUrl(response.qrUrl);
                        setIsOpenQrModal(response.qrUrl !== MESSAGE_SALES_PLAN_FREE_SUCCESS);
                        if (response.qrUrl === MESSAGE_SALES_PLAN_FREE_SUCCESS) {
                            setTitle('notifications.waiting_active');
                        }
                    }

                    setIsLoading(false);
                },
            },
        );
    };

    return (
        <>
            <Connector.default brokerUrl={getEnvConfig.MQTT_URL} options={{ keepalive: 60 }}>
                <Layout className={`flex items-center justify-center ${s.planPage}`}>
                    <Spin spinning={isLoading}>
                        <Form
                            form={form}
                            layout="vertical"
                            className={s.planContainer}
                            onFinish={handleSubmit}
                            disabled={!!id}
                        >
                            <section className={s.headerContainer}>
                                <div className={s.logoContainer}>
                                    <img
                                        src="/images/ffood_logo.png"
                                        alt="logo"
                                        className={s.logoImg}
                                    />
                                </div>
                                <p className={`mr-2 ${s.titleForm}`}>{t('plan_form.title')}</p>
                            </section>

                            <section className={s.formContainer}>
                                <div className={`flex flex-col ${s.formWrapper}`}>
                                    <Row className="w-full" gutter={12}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.email')}
                                                name={FieldTypeEnum.EMAIL}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'plan_form.fields.email_required',
                                                        ),
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: t(
                                                            'plan_form.fields.email_invalid',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t(
                                                        'plan_form.fields.email_placeholder',
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {!id && (
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label={t('plan_form.fields.password')}
                                                    name={FieldTypeEnum.PASSWORD}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'plan_form.fields.password_required',
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        placeholder={t(
                                                            'plan_form.fields.password_placeholder',
                                                        )}
                                                        iconRender={(visible) =>
                                                            visible ? (
                                                                <EyeTwoTone />
                                                            ) : (
                                                                <EyeInvisibleOutlined />
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.full_name')}
                                                name={FieldTypeEnum.FULL_NAME}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'plan_form.fields.full_name_required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={t(
                                                        'plan_form.fields.full_name_placeholder',
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.phone_number')}
                                                name={FieldTypeEnum.PHONE_NUMBER}
                                                rules={[
                                                    {
                                                        pattern: /\(\d{3}\)-\d{3}-\d{4}/,
                                                        message: t('settings.phone_number_invalid'),
                                                    },
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'settings.phone_number_required',
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <MaskedInput
                                                    disabled={!!id}
                                                    mask={
                                                        //  https://imask.js.org/guide.html#masked-pattern
                                                        '(000)-000-0000'
                                                    }
                                                    placeholder={form.getFieldValue(
                                                        FieldTypeEnum.PHONE_NUMBER,
                                                    )}
                                                    value={form.getFieldValue(
                                                        FieldTypeEnum.PHONE_NUMBER,
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.qty_table')}
                                                name={FieldTypeEnum.QTY_TABLE}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'plan_form.fields.qty_table_required',
                                                        ),
                                                    },
                                                    {
                                                        validator: async (_, value) => {
                                                            if (value && !validateIntNumber(value))
                                                                return Promise.reject(
                                                                    t(
                                                                        'plan_form.fields.number_invalid',
                                                                        {
                                                                            ns: 'common',
                                                                        },
                                                                    ),
                                                                );
                                                            if (value === 0) {
                                                                return Promise.reject(
                                                                    t(
                                                                        'plan_form.fields.qty_table_greater_than_zero',
                                                                    ),
                                                                );
                                                            }

                                                            const servicePackageFree =
                                                                extraAppPlans?.data?.find(
                                                                    (extraAppPlan) =>
                                                                        extraAppPlan.title ===
                                                                        'FREE',
                                                                );

                                                            const isSelectServicePackageFree =
                                                                extraAppPlanId ===
                                                                servicePackageFree?.id;

                                                            if (
                                                                !value ||
                                                                !isSelectServicePackageFree ||
                                                                (isSelectServicePackageFree &&
                                                                    value <= MAX_QTY_TABLE_FREE)
                                                            ) {
                                                                return Promise.resolve();
                                                            }

                                                            return Promise.reject(
                                                                t(
                                                                    'plan_form.fields.qty_table_less_than_or_equal',
                                                                ),
                                                            );
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    placeholder={t(
                                                        'plan_form.fields.qty_table_placeholder',
                                                    )}
                                                    onChange={(value: number | null) =>
                                                        setQtyTable(value ?? 1)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.expired_time')}
                                                name={FieldTypeEnum.EXPIRED_TIME}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'plan_form.fields.expired_time_required',
                                                        ),
                                                    },
                                                    {
                                                        validator: async (_, value) => {
                                                            if (value && !validateIntNumber(value))
                                                                return Promise.reject(
                                                                    t(
                                                                        'plan_form.fields.number_invalid',
                                                                        {
                                                                            ns: 'common',
                                                                        },
                                                                    ),
                                                                );
                                                            if (
                                                                (!value && value !== 0) ||
                                                                value > 0
                                                            ) {
                                                                return Promise.resolve();
                                                            }

                                                            return Promise.reject(
                                                                t(
                                                                    'plan_form.fields.expired_time_greater_than_zero',
                                                                ),
                                                            );
                                                        },
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    type="number"
                                                    pattern="[0-9]*"
                                                    addonAfter={t('plan_form.year')}
                                                    placeholder={t(
                                                        'plan_form.fields.expired_time_placeholder',
                                                    )}
                                                    onChange={(value: number | null) =>
                                                        setExpiredTime(value ?? 0)
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label={t('plan_form.fields.reference_code')}
                                                name={FieldTypeEnum.REFERENCE_CODE}
                                            >
                                                <Input
                                                    placeholder={t(
                                                        'plan_form.fields.reference_code_placeholder',
                                                    )}
                                                    value={refCode}
                                                    onChange={(e) => setRefCode(e.target.value)}
                                                    disabled={
                                                        !!(
                                                            id ||
                                                            (extraAppPlanId && planPrice === 0)
                                                        )
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        label={t('plan_form.fields.service_package')}
                                        name={FieldTypeEnum.SERVICE_PACKAGE}
                                        rules={[
                                            {
                                                required: !extraAppPlanId,
                                                message: t(
                                                    'plan_form.fields.service_package_required',
                                                ),
                                            },
                                        ]}
                                        className="mb-0"
                                    >
                                        <Row className="w-full" gutter={[12, 12]}>
                                            {extraAppPlans?.data?.map((extraAppPlan) => (
                                                <Col key={extraAppPlan.id} xs={24} md={8}>
                                                    <button
                                                        type="button"
                                                        className={`text-center w-full ${
                                                            extraAppPlanId === extraAppPlan.id
                                                                ? `${s.selectedService}`
                                                                : `${s.planItem}`
                                                        }`}
                                                        disabled={
                                                            !!(
                                                                extraAppPlan.pricing === 0 &&
                                                                refCode
                                                            )
                                                        }
                                                        onClick={() => {
                                                            if (id) return;
                                                            setPlanPrice(extraAppPlan.pricing);
                                                            setExtraAppPlanId(extraAppPlan.id);
                                                            setExtraAppPlanName(extraAppPlan.title);

                                                            form.validateFields([
                                                                FieldTypeEnum.SERVICE_PACKAGE,
                                                                FieldTypeEnum.QTY_TABLE,
                                                            ]);
                                                        }}
                                                    >
                                                        <div className={s.iconPlan}>
                                                            <img
                                                                src="/images/icons/plan.svg"
                                                                alt="dots-vertical"
                                                                className={s.actionIcon}
                                                            />
                                                        </div>
                                                        <p className={s.namePlan}>
                                                            {extraAppPlan.title}
                                                        </p>
                                                        <p className={s.pricePlan}>
                                                            {formatPriceBaseOnCurrency(
                                                                extraAppPlan.pricing,
                                                                CurrencyType.Vnd,
                                                            )}
                                                            {extraAppPlan.pricing
                                                                ? `/${t('plan_form.pc_month')}`
                                                                : ''}
                                                        </p>
                                                    </button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Form.Item>
                                </div>
                            </section>
                            {registration?.data.isPaid ? (
                                <></>
                            ) : (
                                <div className={s.btnContainer}>
                                    <Button
                                        type="primary"
                                        className={s.btn}
                                        htmlType="submit"
                                        disabled={false}
                                    >
                                        {!id
                                            ? t('common.submit')
                                            : t('buttons.subscribe', { ns: 'common' })}
                                    </Button>
                                </div>
                            )}

                            <ReCAPTCHA
                                size="invisible"
                                badge="bottomleft"
                                ref={recaptchaRef}
                                sitekey={CAPTCHA_SITE_KEY}
                            />
                        </Form>
                    </Spin>
                </Layout>

                {qrUrl && (
                    <QRModal
                        url={qrUrl}
                        planPrice={planPrice}
                        isOpen={isOpenQrModal}
                        expiredTime={expiredTime}
                        qtyTable={qtyTable}
                        onClose={() => {
                            setQrUrl(undefined);
                            setIsOpenQrModal(false);
                        }}
                        discount={discount}
                    />
                )}

                {(tenantId || title) && (
                    <NotificationPaymentModal
                        tenantId={tenantId}
                        onClose={() => {
                            setTenantId('');
                            setTitle('');
                            form.resetFields();
                            recaptchaRef.current.reset();
                        }}
                        onCloseQrModal={() => {
                            setQrUrl(undefined);
                            setIsOpenQrModal(false);
                        }}
                        title={title}
                        id={id}
                    />
                )}
            </Connector.default>
        </>
    );
};
